import { render, staticRenderFns } from "./indexNew.vue?vue&type=template&id=3d0719af&scoped=true"
import script from "./indexNew.vue?vue&type=script&lang=js"
export * from "./indexNew.vue?vue&type=script&lang=js"
import style0 from "./indexNew.vue?vue&type=style&index=0&id=3d0719af&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d0719af",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\project\\ruge-web\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3d0719af')) {
      api.createRecord('3d0719af', component.options)
    } else {
      api.reload('3d0719af', component.options)
    }
    module.hot.accept("./indexNew.vue?vue&type=template&id=3d0719af&scoped=true", function () {
      api.rerender('3d0719af', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ruge/vlink/alarm/indexNew.vue"
export default component.exports