<template>
  <div class="app-container alarm-list-comp">
    <div class="top-button-line">
      <span>
        <el-button plain icon="el-icon-refresh-right" @click="freshHandler"
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
        <template v-slot:isConfirmSlot="row">
          <span>
            {{
              ConfirmMaps[row.datas.handleType] ||
              row.datas.handleType ||
              "未确认"
            }}
          </span>
        </template>
        <template v-slot:alarmStatusSlot="row">
          <el-tag
            :type="
              row.datas.alarmStatus === 'alarm' && !row.datas.handleType
                ? 'danger'
                : 'success'
            "
            >{{
              row.datas.alarmStatus === "normal"
                ? "恢复"
                : row.datas.handleType
                ? "已确认"
                : "告警"
            }}</el-tag
          >
        </template>
        <template v-slot:productKeySlot>
          <el-select
            v-model="dataset.advanceFilterConfig.productKey.value"
            multiple
            collapse-tags
            filterable
            placeholder=""
            style="width: 100%"
          >
            <el-option
              v-for="item in productKeyOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </template>
        <template v-slot:deviceNameSlot>
          <el-select
            v-model="dataset.advanceFilterConfig.deviceName.value"
            multiple
            collapse-tags
            placeholder=""
            :remote-method="remoteMethod"
            remote
            filterable
            style="width: 100%"
          >
            <el-option
              v-for="item in deviceNameOptions"
              :key="item.productKey + item.deviceName"
              :label="item.deviceName"
              :value="item.deviceName"
            >
              <span style="float: left">{{ item.deviceName }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.description
              }}</span>
            </el-option>
          </el-select>
        </template>
        <template v-slot:deviceNameSearchSlot>
          <el-select
            v-model="dataset.searchLineConfig.deviceName.value"
            multiple
            collapse-tags
            placeholder=""
            :remote-method="remoteMethod"
            remote
            filterable
            style="width: 100%"
            @change="findByDeviceNameList"
          >
            <el-option
              v-for="item in deviceNameOptions"
              :key="item.productKey + item.deviceName"
              :label="item.deviceName"
              :value="item.deviceName"
            >
            </el-option>
          </el-select>
        </template>
      </finalTable>
    </div>
    <AlarmConfirmDialog
      :dialogShow="alarmDialog.alarmDialogShow"
      :dataset="alarmDialog.datas"
      @close="alarmDialogClose"
    />
  </div>
</template>

<script>
import { findAlarmPage } from "@/api/ruge/vlink/alarm/alarm";
import finalTable from "@/components/FinalTable";
import AlarmConfirmDialog from "./confirmDialog.vue";
import { getProductList } from "@/api/ruge/vlink/product/product";
import { findByDNOrDescription } from "@/api/ruge/vlink/device/device";
import { getUrlParams } from "@/utils/utils.js";
const ConfirmMaps = {
  handle: "处理",
  noHandle: "无需处理",
  misreport: "误报",
};
export default {
  name: "alermListComponent",
  components: {
    finalTable,
    AlarmConfirmDialog,
  },
  data() {
    return {
      ConfirmMaps,
      productKeyOptions: [],
      deviceNameOptions: [],
      resourceOptions: [],
      alarmDialog: {
        alarmDialogShow: false,
        datas: {},
      },
      loadingFlag: false,
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        header: [
          { prop: "alarmUUID", label: "告警ID", width: "280" },
          { prop: "alarmTimestamp", label: "告警时间", width: "" },
          { prop: "deviceName", label: "设备标识码", width: "" },
          { prop: "alarmLevel", label: "告警级别", width: "" },
          { prop: "alarmType", label: "告警类型", width: "" },
          { prop: "alarmContent", label: "告警内容", width: "" },
          { prop: "alarmValue", label: "告警值", width: "" },
          // { prop: "productAndDevice", label: "产品/设备", width: "" },
          // { prop: "moduleAndProp", label: "模块/属性", width: "" },
          { prop: "isConfirm", label: "是否确认", width: "" },
          { prop: "alarmStatus", label: "告警状态", width: "" },
          { prop: "operation", label: "操作", width: "150" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          alarmUUID: {
            type: "input",
            label: "告警ID",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入告警ID",
            prefixIcon: "el-icon-search",
          },
          deviceName: {
            type: "slot",
            slotName: "deviceNameSearchSlot",
            value: [],
            label: "设备",
          },
          isConfirm: {
            type: "selector",
            label: "是否确认",
            value: "",
            placeholder: "请选择是否确认",
            actionType: "goSearch",
            optionList: [
              {
                label: "是",
                value: "Y",
              },
              {
                label: "否",
                value: "N",
              },
            ],
          },
          alarmLevel: {
            type: "selector",
            label: "告警级别",
            value: "",
            placeholder: "请选择告警级别",
            actionType: "goSearch",
            optionList: [
              {
                label: "提示",
                value: "info",
              },
              {
                label: "一般",
                value: "warn",
              },
              {
                label: "严重",
                value: "error",
              },
            ],
          },
          alarmType: {
            type: "selector",
            label: "告警类型",
            value: "",
            placeholder: "请选择告警类型",
            actionType: "goSearch",
            optionList: [
              {
                label: "运行",
                value: "run",
              },
              {
                label: "故障",
                value: "fault",
              },
            ],
          },
          alarmContent: {
            type: "input",
            label: "告警内容",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入告警内容",
            prefixIcon: "el-icon-search",
          },
          alarmValue: {
            type: "input",
            label: "告警值",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入告警值",
            prefixIcon: "el-icon-search",
          },
          // productAndDevice: {
          //   type: "input",
          //   label: "产品/设备",
          //   value: "",
          //   actionType: "goSearch",
          //   placeholder: "请输入产品/设备名称",
          //   prefixIcon: "el-icon-search",
          // },
          // moduleAndProp: {
          //   type: "input",
          //   label: "模型/属性",
          //   value: "",
          //   actionType: "goSearch",
          //   placeholder: "请输入模型/属性名称",
          //   prefixIcon: "el-icon-search",
          // },
          alarmStatus: {
            type: "selector",
            label: "告警状态",
            value: "",
            placeholder: "请选择告警状态",
            actionType: "goSearch",
            optionList: [
              {
                label: "告警",
                value: "alarm",
              },
              {
                label: "恢复",
                value: "normal",
              },
            ],
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          isConfirm: {
            type: "slot",
            slotName: "isConfirmSlot",
          },
          alarmUUID: {
            type: "aLink",
            actionType: "aLinkClick",
            targetType: "alarm",
          },
          alarmTimestamp: {
            type: "dateFormat",
          },
          alarmLevel: {
            type: "enumerationColumn",
            emuList: {
              info: "提示",
              warn: "一般",
              error: "严重",
            },
          },
          alarmType: {
            type: "enumerationColumn",
            emuList: {
              run: "运行",
              fault: "故障",
            },
          },
          // productAndDevice: {
          //   type: "joinList",
          //   joinSymbol: "/",
          //   joinList: ["productKey", "deviceName"],
          // },
          // moduleAndProp: {
          //   type: "joinList",
          //   joinSymbol: "/",
          //   joinList: ["moduleName", "propertyName"],
          // },
          // alarmStatus: {
          //   type: "tags",
          //   alarm: {
          //     type: "danger",
          //     label: "告警",
          //   },
          //   normal: {
          //     type: "success",
          //     label: "恢复",
          //   },
          // },
          alarmStatus: {
            type: "slot",
            slotName: "alarmStatusSlot",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看详情",
              },
              {
                actionType: "iconClick",
                eventName: "confirm",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "确认告警",
                hideProp: "hideConfirm",
                hideValue: true,
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          productKey: {
            type: "slot",
            slotName: "productKeySlot",
            value: [],
            label: "产品",
          },
          deviceName: {
            type: "slot",
            slotName: "deviceNameSlot",
            value: [],
            label: "设备",
            special: true,
          },
          alarmUUID: {
            inline: true,
            value: "",
          },
          alarmLevel: {
            inline: true,
            value: "",
          },
          timestamp: {
            type: "dateRange",
            label: "告警时间",
            // value: [new Date(moment().subtract(7, "days")).getTime(), null],
            value: [null, null],
          },
          alarmType: {
            inline: true,
            value: "",
          },
          alarmContent: {
            inline: true,
            value: "",
          },
          alarmValue: {
            inline: true,
            value: "",
          },
          // productAndDevice: {
          //   inline: true,
          //   value: "",
          // },
          // moduleAndProp: {
          //   inline: true,
          //   value: "",
          // },
          alarmStatus: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      alarm: {
        title: null,
        total: 0,
        list: null,
        listQuery: {
          current: 1,
          rowCount: 10,
          alarmKey: null,
          deviceName: null,
          alarmType: null,
          alarmLevel: null,
          alarmStatus: null,
          productKey: null,
          // startTime: new Date(moment().subtract(3, "days")).getTime(),
          startTime: null,
          endTime: null,
        },
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getUrlQuerys();
    this.initOptions();
  },
  methods: {
    getUrlQuerys() {
      //获取连接上携带的参数
      const qData = this.$route.query;
      for (let key in qData) {
        qData[key] && this.updateSingleKey(key, qData);
      }
      this.getAlarmPage();
    },
    updateSingleKey(key, datas) {
      const value = datas[key];
      switch (key) {
        case "alarmUUID":
          this.alarm.listQuery.alarmUUID = value;
          this.dataset.searchLineConfig.alarmUUID.value = value;
          break;
        case "deviceName":
          this.alarm.listQuery.deviceName = value;
          this.dataset.searchLineConfig.deviceName.value = value.split(",");
          this.dataset.advanceFilterConfig.deviceName.value = value.split(",");
          break;
        case "alarmLevel":
          this.alarm.listQuery.alarmLevel = value;
          this.dataset.searchLineConfig.alarmLevel.value = value;
          break;
        case "alarmType":
          this.alarm.listQuery.alarmType = value;
          this.dataset.searchLineConfig.alarmType.value = value;
          break;
        case "alarmContent":
          this.alarm.listQuery.alarmContent = value;
          this.dataset.searchLineConfig.alarmContent.value = value;
          break;
        case "alarmValue":
          this.alarm.listQuery.alarmValue = value;
          this.dataset.searchLineConfig.alarmValue.value = value;
          break;
        case "isConfirm":
          this.alarm.listQuery.isConfirm = value;
          this.dataset.searchLineConfig.isConfirm.value = value;
          break;
        case "alarmStatus":
          this.alarm.listQuery.alarmStatus = value;
          this.dataset.searchLineConfig.alarmStatus.value = value;
          break;
        case "productKey":
          this.alarm.listQuery.productKey = value;
          this.dataset.advanceFilterConfig.productKey.value = value.split(",");
          break;
        case "startTime":
          this.dataset.advanceFilterConfig.timestamp.value = [
            Number(value),
            datas["endTime"] ? Number(datas["endTime"]) : null,
          ];
          this.alarm.listQuery.startTime = value;
          break;
        case "endTime":
          this.dataset.advanceFilterConfig.timestamp.value = [
            datas["startTime"] ? Number(datas["startTime"]) : null,
            Number(value),
          ];
          this.alarm.listQuery.endTime = value;
          break;
      }
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        this.getDeviceList(query);
      } else {
        this.deviceNameOptions = this.resourceOptions;
      }
    },
    findByDeviceNameList() {
      const devices = this.dataset.searchLineConfig.deviceName.value;
      console.log("devices", devices);
      this.alarm.listQuery.deviceName = devices.join(",");
      this.dataset.advanceFilterConfig.deviceName.value = devices;
      this.resetCurrentAndGetTableList();
    },
    initOptions() {
      console.log("tanghong");
      getProductList().then((res) => {
        this.productKeyOptions = res.map((item) => {
          return {
            label: item.productName,
            value: item.productKey,
          };
        });
      });
      this.getDeviceList();
    },
    getDeviceList(filter) {
      findByDNOrDescription({
        filter,
      }).then((res) => {
        if (!this.resourceOptions.length) {
          this.resourceOptions = res;
        }
        this.deviceNameOptions = res;
      });
    },
    alarmDialogClose(freshFlag) {
      this.alarmDialog.alarmDialogShow = false;
      freshFlag && this.getAlarmPage();
    },
    freshHandler(evt) {
      let target = evt.target;
      if (target.nodeName == "SPAN") {
        target = evt.target.parentNode;
      }
      target.blur();
      this.getAlarmPage();
    },
    findAlarmPage() {
      this.alarm.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      this.getAlarmPage();
    },
    resetCurrentAndGetTableList() {
      this.alarm.listQuery.current = 1;
      this.dataset.pageVO.current = 1;
      let str = "";
      let dataObj = { ...this.$route.query, ...this.alarm.listQuery };
      for (let keys in dataObj) {
        let val = dataObj[keys];
        if (keys && val) {
          str += keys + "=" + val + "&";
        }
      }
      this.$router.push(this.$route.path + "?" + str);
      this.getAlarmPage();
    },
    getAlarmPage() {
      let param = this.alarm.listQuery;
      sessionStorage.setItem("alarm_list_query_params", JSON.stringify(param));
      for (let pn in param) {
        !param[pn] && param[pn] !== 0 && (param[pn] = null);
      }
      this.loadingFlag = true;
      findAlarmPage(param)
        .then((response) => {
          this.dataset.pageVO.total = response.total;
          this.dataset.tableData = response.rows.map((item) => {
            // 如果有handleTime或者alarmTimestamp为空时，隐藏确认按钮
            item.hideConfirm =
              item.handleTime || !item.alarmTimestamp ? true : false;
            return item;
          });
          console.log("this.dataset.tableData", this.dataset.tableData);
          this.loadingFlag = false;
        })
        .catch((err) => {
          console.log("error: " + err);
          this.loadingFlag = false;
        });
    },
    confirmHandler(datas) {
      this.alarmDialog.alarmDialogShow = true;
      this.alarmDialog.datas = datas;
    },
    dealParams(datas) {
      if (datas.params.productKey) {
        datas.params.productKey = datas.params.productKey.join(",");
      }
      if (datas.params.deviceName) {
        datas.params.deviceName = datas.params.deviceName.join(",");
      }
      return datas;
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.dealParams(datas);
        if (datas.params.timestamp && datas.params.timestamp.length > 0) {
          this.alarm.listQuery.startTime = datas.params.timestamp[0]
            ? new Date(
                JSON.parse(JSON.stringify(datas.params.timestamp[0]))
              ).getTime()
            : "";
          this.alarm.listQuery.endTime = datas.params.timestamp[1]
            ? new Date(
                JSON.parse(JSON.stringify(datas.params.timestamp[1]))
              ).getTime()
            : "";
        }
        this.alarm.listQuery = { ...this.alarm.listQuery, ...datas.params };
        delete this.alarm.listQuery.timestamp;
        this.resetCurrentAndGetTableList();
      } else if (datas.type === "paginationChange") {
        this.alarm.listQuery.current = datas.params.current.page;
        this.alarm.listQuery.rowCount = datas.params.current.limit;
        this.getAlarmPage();
      } else if (datas.type === "updateSelectionList") {
        this.selectionList = datas.list;
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            this.$router.push({
              path: "/iot/alarm/detail",
              query: {
                alarmId: datas.row.alarmUUID,
              },
            });
            break;
          case "confirm":
            this.confirmHandler(datas.row);
            break;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.alarm-list-comp {
  .top-button-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
  }
  .table-container {
    margin-top: 25px;
  }
}
</style>