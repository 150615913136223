<template>
  <div class="alarm-confirm-dialog">
    <el-dialog
      :visible.sync="dialogShow"
      width="35%"
      :close-on-click-modal="false"
      :before-close="closeAlarmDialog"
      :show-close="true"
      v-loading="confirmLoading"
    >
      <template slot="title">
        <span class="alarm-confirm-title"
          ><title-icon />{{ $t("iot.alarm.alarmConfirm") }}</span
        >
      </template>
      <el-form
        label-position="top"
        ref="confirmForm"
        :model="confirmForm"
        :rules="rules"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('vlink.product.productName')">
              <span>{{ dataset.productName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('iot.device.name')">
              <span>{{ dataset.deviceDescription || dataset.deviceName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="dataset.dataSource !== 'status'">
            <el-form-item :label="$t('vlink.tsl.moduleName')">
              <span>{{ dataset.moduleName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="dataset.dataSource === 'property'">
            <el-form-item :label="$t('vlink.tsl.propertyName')">
              <span>{{
                dataset.propertyDescription || dataset.propertyName
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else-if="dataset.dataSource === 'evnet'">
            <el-form-item :label="$t('vlink.tsl.eventName')">
              <span>{{ dataset.eventDescription || dataset.evnetName }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-else-if="dataset.dataSource === 'status'">
            <el-form-item :label="$t('vlink.rule.status')">
              <span>{{ dataset.normalValue || dataset.alarmValue }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item
              :label="dataset.alarmStatus === 'alarm' ? '发生时间' : '恢复时间'"
            >
              <span>{{ timestampRender(dataset) }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('vlink.rule.alarmStatus')">
              <span>{{
                dataset.alarmStatus === "alarm" ? "告警" : "恢复"
              }}</span>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="确认描述" prop="handleComment">
              <el-input
                type="textarea"
                :rows="3"
                maxlength="160"
                :show-word-limit="true"
                style="width: 100%"
                :placeholder="$t('commons.pleaseInput')"
                v-model="confirmForm.handleComment"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="buttons-container">
        <el-button
          style="background-color: #2a61ff; color: #fff"
          @click="submitHandler('handle')"
          >{{ $t("iot.alarm.faultConfirm") }}</el-button
        >
        <el-button
          style="background-color: #f5b840; color: #fff"
          @click="submitHandler('misreport')"
          >{{ $t("iot.alarm.misreportConfirm") }}</el-button
        >
        <el-button
          style="background-color: #e25b5b; color: #fff"
          @click="submitHandler('noHandle')"
          >{{ $t("iot.alarm.fireConfirm") }}</el-button
        >
        <el-button @click="closeAlarmDialog"> 取消 </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { confirmAlarmNew } from "@/api/ruge/iot/alarm/alarm";
import { dateFormat } from "@/filters/index.js";
export default {
  name: "alarmConfirmDialog",
  props: {
    dialogShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      confirmLoading: false,
      confirmForm: {
        handleComment: "",
      },
      rules: {
        handleComment: [
          { required: true, message: "请输入备注", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    timestampRender(datas) {
      if (datas.alarmStatus === "alarm") {
        return dateFormat(
          new Date(new Number(datas.alarmTimestamp)),
          "YYYY-MM-DD HH:mm:ss"
        );
      } else {
        return dateFormat(
          new Date(new Number(datas.normalTimestamp)),
          "YYYY-MM-DD HH:mm:ss"
        );
      }
    },
    closeAlarmDialog() {
      this.confirmForm.handleComment = "";
      this.$emit("close", false);
    },
    submitHandler(confirmType) {
      this.$refs.confirmForm.validate((v) => {
        if (v) {
          this.confirmAlarm(confirmType);
        }
      });
    },
    confirmAlarm(handleType) {
      this.$confirm(
        this.$t("iot.alarm.comfirmMsg"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      ).then(() => {
        const { alarmUUID, ruleKey, productKey, deviceName, alarmStatus } =
          this.dataset;
        confirmAlarmNew({
          handleComment: this.confirmForm.handleComment,
          handleTime: new Date().getTime(),
          alarmUUID,
          handleType,
          ruleKey,
          productKey,
          deviceName,
          alarmStatus,
        }).then((res) => {
          this.confirmForm.handleComment = "";
          this.$emit("close", true);
        });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.alarm-confirm-dialog {
  .buttons-container {
    text-align: right;
  }
}
</style>