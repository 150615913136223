var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "alarm-confirm-dialog" },
    [
      _c(
        "el-dialog",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.confirmLoading,
              expression: "confirmLoading",
            },
          ],
          attrs: {
            visible: _vm.dialogShow,
            width: "35%",
            "close-on-click-modal": false,
            "before-close": _vm.closeAlarmDialog,
            "show-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogShow = $event
            },
          },
        },
        [
          _c("template", { slot: "title" }, [
            _c(
              "span",
              { staticClass: "alarm-confirm-title" },
              [
                _c("title-icon"),
                _vm._v(_vm._s(_vm.$t("iot.alarm.alarmConfirm"))),
              ],
              1
            ),
          ]),
          _c(
            "el-form",
            {
              ref: "confirmForm",
              attrs: {
                "label-position": "top",
                model: _vm.confirmForm,
                rules: _vm.rules,
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("vlink.product.productName") },
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.dataset.productName))])]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("iot.device.name") } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dataset.deviceDescription ||
                                  _vm.dataset.deviceName
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm.dataset.dataSource !== "status"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: _vm.$t("vlink.tsl.moduleName") },
                            },
                            [
                              _c("span", [
                                _vm._v(_vm._s(_vm.dataset.moduleName)),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.dataset.dataSource === "property"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: _vm.$t("vlink.tsl.propertyName"),
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.dataset.propertyDescription ||
                                      _vm.dataset.propertyName
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm.dataset.dataSource === "evnet"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("vlink.tsl.eventName") } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.dataset.eventDescription ||
                                      _vm.dataset.evnetName
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm.dataset.dataSource === "status"
                    ? _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: _vm.$t("vlink.rule.status") } },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.dataset.normalValue ||
                                      _vm.dataset.alarmValue
                                  )
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label:
                              _vm.dataset.alarmStatus === "alarm"
                                ? "发生时间"
                                : "恢复时间",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(_vm._s(_vm.timestampRender(_vm.dataset))),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("vlink.rule.alarmStatus") } },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.dataset.alarmStatus === "alarm"
                                  ? "告警"
                                  : "恢复"
                              )
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "确认描述", prop: "handleComment" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "100%" },
                            attrs: {
                              type: "textarea",
                              rows: 3,
                              maxlength: "160",
                              "show-word-limit": true,
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: _vm.confirmForm.handleComment,
                              callback: function ($$v) {
                                _vm.$set(_vm.confirmForm, "handleComment", $$v)
                              },
                              expression: "confirmForm.handleComment",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "buttons-container" },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "background-color": "#2a61ff", color: "#fff" },
                  on: {
                    click: function ($event) {
                      return _vm.submitHandler("handle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("iot.alarm.faultConfirm")))]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "background-color": "#f5b840", color: "#fff" },
                  on: {
                    click: function ($event) {
                      return _vm.submitHandler("misreport")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("iot.alarm.misreportConfirm")))]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { "background-color": "#e25b5b", color: "#fff" },
                  on: {
                    click: function ($event) {
                      return _vm.submitHandler("noHandle")
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("iot.alarm.fireConfirm")))]
              ),
              _c("el-button", { on: { click: _vm.closeAlarmDialog } }, [
                _vm._v(" 取消 "),
              ]),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }